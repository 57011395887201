import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { tokenNotExpired } from "angular2-jwt";
@Injectable({
  providedIn: "root"
})
export class AuthService {
  public authToken: any;
  public user: any;
  public headers: Headers;
  public baseUrl: string = "https://develop-yoferretero-admin.imagineapps.co/";
  //public baseUrl: string = "http://localhost:8081/";

  constructor(public http: Http) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.user = JSON.parse(localStorage.getItem("userYFadmin")) || {};
  }
  public authenticateUser(user) {
    console.log(this.baseUrl + "users/authenticate", JSON.stringify(user))
    return this.http
      .post(this.baseUrl + "users/authenticate", JSON.stringify(user), {
        headers: this.headers
      })
      .pipe(map(res => res.json()));
  }
  public storeUserData(token, user) {
    localStorage.setItem("userYFadmin", JSON.stringify(user));
    localStorage.setItem("tkYFadmin", token);
    this.authToken = token;
    this.user = user;
  }
  public forgot(email) {
    const obj = { email };
    return this.http
      .post(this.baseUrl + "users/forgot", JSON.stringify(obj), {
        headers: this.headers
      })
      .pipe(map(res => res.json()));
  }
  public change(email, password, passwordNew) {
    const obj = { email, password, passwordNew };
    return this.http
      .post(this.baseUrl + "users/reset", JSON.stringify(obj), {
        headers: this.headers
      })
      .pipe(map(res => res.json()));
  }

  public loadToken() {
    const token = localStorage.getItem("tkYFadmin");
    this.authToken = token;
  }
  public logout() {
    this.authToken = null;
    this.user = {};
    localStorage.removeItem("tkYFadmin")
    localStorage.removeItem("userYFadmin")
  }
  public getToken() {
    this.loadToken();
    return this.authToken;
  }
  public loggedIn() {
    return tokenNotExpired("tkYFadmin");
  }
  public getUser() {
    return this.user;
  }

  /*public validateJwtExpired(JWT) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    return this.http.post(`${this.baseUrl}users/validate-JWT-expired`, { JWT: JWT }, {
      headers: this.headers
    });
  }*/
}
