import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class MainService {
  public user: any;
  public headers: Headers;
  public baseUrl: string = "https://develop-yoferretero-admin.imagineapps.co/";
  // public baseUrl: string = "http://localhost:8081/";
  constructor(public http: Http, private authService: AuthService) { 
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.authService.getToken());
    this.user = JSON.parse(localStorage.getItem("userYFweb")) || {};
  }
   // =======================================
  post2(object: String, data: any) {
    return this.http
      .post(this.baseUrl + "" + object, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }

  delete2(object: String) {
    return this.http
      .delete(this.baseUrl + "" + object, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  put2(object: String, data: any) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Authorization', this.authService.getToken());
    return this.http
      .put(this.baseUrl + "" + object, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }

  /**
   * API Generico GET
   * @param {string} api route: api/componente
   * @return {Observable<any>} respuesta asincrónica
   */
  get({ api }: { api: String }) {
    return this.http
      .get(`${this.baseUrl}${api}`, { headers: this.headers })
      .pipe(map(res => res.json()));
  }
  get2(object: String) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Authorization', this.authService.getToken());
    return this.http
      .get(this.baseUrl + "" + object, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  post({ api, data }: { api: String; data: any }) {
    return this.http
      .post(`${this.baseUrl}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }

  /**
   * API Generico DELETE
   * @param {string} api route: api/componente/id
   * @return {Observable<any>} respuesta asincrónica
   */
  delete({ api }: { api: String }) {
    return this.http
      .delete(`${this.baseUrl}${api}`, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  /**
   * API Generico PUT
   * @param {string} api route: api/componente/id
   * @param {any} data propiedades a actualizar
   * @return {Observable<any>} respuesta asincrónica
   */
  put({ api, data }: { api: String; data: any }) {
    return this.http
      .put(`${this.baseUrl}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }
}
